import React, { useEffect, useState } from 'react'
import getApiCall from "../../Services/getApiCall";
import swal from "sweetalert";
import postApiCall from "../../Services/postApiCall";
import { Button, Modal } from "react-bootstrap";
import PaginationNew from "../../Widgets/PaginationNew";
import deleteApiCall from "../../Services/deleteApiCall";
import Constant from "../../Components/Constant";
import { userDetails } from "../../Services/authenticationService";
import useGetRoleModule from '../../Services/useGetRoleModule';
import axios from "axios";
import loginUser from "../../Services/loginUser";
import { formatDate } from "../../Services/helpers";
// import RefundModal from "../Bidder/RefundModal";
import EmdRefundModal from "./EmdRefundModal";
import moment from 'moment';

const EmdPayments = () => {
    let { accessToken } = loginUser();

    const [lists, setLists] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [perPage, setPerPage] = useState(Constant.perPage);
    const [isLoaded, setIsLoaded] = useState(false);
    const [searchForm, setSearchForm] = useState({
        searchKey: '',
        paymentStatus: '',
        paymentType: "",
        isExcelDownload: false,
        paymentDate: '',
    });
    const [permission, setPermission] = useState({})
    const [auctionPermission, setAuctionPermission] = useState({})


    const user = userDetails();

    function changeSearchForm(e) {
        if (!e.target.name) return;
        setSearchForm({
            ...searchForm,
            [e.target.name]: e.target.value
        })
    }

    const onChangeFilter = (e) => {
        if (!e.target.name) return
      //  console.log(e.target.value)
        setSearchForm({
            ...searchForm,
            [e.target.name]: e.target.value
        })
    }
    const submitFilter = (e) => {
        e.preventDefault();
        getList()
        // getEmenities()
    }


    async function getList() {
        console.clear()
        let response = await getApiCall('user/transaction/list', {
            'page': pageNo,
            'contentPerPage': perPage,
            'searchKey': searchForm.searchKey,
            'paymentStatus': searchForm.paymentStatus,
            "paymentFor": searchForm.paymentFor == "" ? "" : searchForm.paymentFor,
            "paymentDate": searchForm.paymentDate == '' ? '': searchForm.paymentDate,
            "isExcelDownload": searchForm.isExcelDownload == "" ? false : searchForm.isExcelDownload
        });
        if (response.meta.status) {
            const curr = moment().valueOf();

            const records = response.data.map((item, i) => {
                // console.log(item.propertyDetails?.auctionEndDateTimeEpoch , curr, i)
                if (item.paymentStatus == 'COMPLETED') {

                    if (item?.bidderBids?.isBidAwarded) {
                        /**
                         * Highest Bidder : Bidder status as H1 or win
                         */
                        return { ...item, refundShown: false }
                    } else if (item.paymentFor == 3) {
                        /**
                         * Highest Bidder payment of Balance 75% of Sale Amount is not received in 30 days
                         */
                        return { ...item, refundShown: false }
                    }
                    // else if(item.propertyDetails == undefined || item.propertyDetails.isForfeit == true){
                    //     /**
                    //      * Sole Bidder means # Bidders who paid EMD is equal to 1 : Bidder status : Not Participated
                    //      */
                    //     return { ...item, refundShown: false}
                    // }
                    // else if(item.propertyDetails == undefined || item.propertyDetails.auctionStatus != "COMPLETED"){

                    else if (item.propertyDetails == undefined || item.propertyDetails.auctionEndDateTimeEpoch > curr) {
                        /**
                         *  auction completed
                         */
                        // console.log("less")
                        return { ...item, refundShown: false }
                    }
                    else {
                        return { ...item, refundShown: true }
                    }

                } else {
                    //payment status not completed
                    return { ...item, refundShown: false }
                }

            })
            // setLists(response.data)
            setLists(records)
        } else {
            setLists([])
        }
        setTotalItems(response.total)
        setIsLoaded(true);
    }

    async function refundDisableCondition(date) {
        var today = new Date();
        var givenDate = new Date(date);

        if (givenDate < today) {
            return false;
        } else if (givenDate > today) {
            return true;
        } else {
            return false;
        }

    }

    async function exportPayment() {
        console.log("primary")
    }

    async function GetRole() {

        let Role = await useGetRoleModule("emd-payments");
        // let autionRole = await useGetRoleModule("property/auction-bulk-upload");
        if (Role.moduleList.read === false) {
            setPermission({ moduleAccress: false, moduleList: {}, message: "Module Need Some Permission...Pls contact with Your Partner" })
        } else {
            setPermission(Role)
        }

        // getList();
    }

    useEffect(() => {
        GetRole()
    }, []);


    async function downloadList(e) {
        e.preventDefault();
        // Download to export
        const response = await axios({
            url: Constant.apiBasePath + 'user/transaction/list',
            method: 'GET',
            responseType: 'blob',
            headers: {
                authkey: accessToken
            },
            params: { ...searchForm, isExcelDownload: true }
        });
        // getApiCall('user/bid/bidder/list', )
        //     .then((response) => {
        const url = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'payment.csv';
        document.body.appendChild(link);
        link.click();
        // URL.revokeObjectURL(url)
        // })


        // let data = filterForm
        // data.exportData = 1;
        // getList(data)
    }


    function UpdateStatus(e) {
        let id = e.currentTarget.getAttribute('value');
        let status = e.currentTarget.getAttribute('status');
        let isDeleted = e.currentTarget.getAttribute('isDeleted');
        // console.log('status', status)
        // status = status === 1 ? "active" : "deactive"
        status = status == 0 ? "active" : "deactive"
        // console.log('status', status)

        postApiCall('user/property/status', {
            status: status,
            "_id": id,
            // isDeleted: isDeleted
        }).then((response) => {
            if (response.meta.status) {
                swal({ text: response.meta.msg, icon: "success", timer: 1500 })
                getList();
            }
        });
    }


    function DeleteEvent(e) {
        let id = e.currentTarget.getAttribute('value');
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteApiCall('user/property/delete/' + id, {}).then((response) => {
                    if (response.meta.status) {
                        swal({ text: response.meta.msg, icon: "success", timer: 1500 })
                        getList();
                    }
                });
            } else {
                // swal("Your imaginary file is safe!");
            }
        });

    }

    const exportToExcel = async () => {
        try {
            const response = await axios({
                url: Constant.apiBasePath + 'user/property/download',
                method: 'GET',
                responseType: 'blob',
                headers: {
                    authkey: accessToken
                }
            });

            // const contentDispositionHeader = response.headers['content-disposition'];
            // const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            // const fileNameMatch = fileNameRegex.exec(contentDispositionHeader);
            // const suggestedFileName = fileNameMatch[1].replace(/['"]/g, '');

            const url = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'properties.xlsx';
            document.body.appendChild(link);
            link.click();
            // Clean up the URL object when the download is complete
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error(error);
        }
    };

    function convertDate(date) {
        let d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [day, month, year,].join('-');
    }

    function pageChangeHandler(page) {
        if (isLoaded) {
            setPageNo(page);
        }
    }



    useEffect(() => {
        getList()
    }, [pageNo])

    return (
        <>
            <div className="container-fluid">
                <div className="main-title">
                    <h3>Payment Management</h3>
                </div>

                {permission.hasOwnProperty('moduleAccress') && !permission.moduleAccress ?

                    <div className="row text-center">
                        <div className="col-sm-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                            <div className="errer">
                                <img src="/program-error.png" />
                                <h2>403</h2>
                                {/* <h4 className="text-danger">{permission.message}</h4> */}
                                <p>{permission.message}</p>

                            </div>
                        </div>
                    </div>
                    :
                    <>

                        <div className="card shadow mb-4">
                            {/*Search and Filter From*/}
                            <div className="card-body">
                                <form onSubmit={submitFilter} onChange={onChangeFilter}>
                                    <div className="row">
                                        <div className="col-12 col-xs-12 col-md-9 col-lg-9">
                                            <div className="row">
                                                <div className="col-12 col-xs-3 col-md-3 col-lg-3">
                                                    <div className="form-group">
                                                        <label>Search </label>
                                                        <input type="text" className="form-control" name="searchKey"
                                                            value={searchForm.searchKey}
                                                            placeholder="Search By Name, Prospect, Auction ..."
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xs-3 col-md-3 col-lg-3">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlSelect1">Payment Status</label>
                                                        <select name="paymentStatus" value={searchForm.paymentStatus}
                                                            className="form-control">
                                                            <option value="">Select</option>
                                                            <option value="PENDING">Pending</option>
                                                            <option value="COMPLETED">Completed</option>
                                                            <option value="REFUNDED">Refunded</option>
                                                            <option value="REJECTED">Failed</option>
                                                        </select>
                                                    </div>

                                                </div>

                                                <div className="col-12 col-xs-3 col-md-3 col-lg-3">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlSelect1">Payment Type</label>
                                                        <select name="paymentFor" value={searchForm.paymentFor}
                                                            className="form-control">
                                                            <option value="">Select</option>
                                                            <option value="2">EMD</option>
                                                            <option value="1">EOI</option>
                                                            <option value="3">Balance Sale Amount</option>
                                                        </select>
                                                    </div>

                                                </div>
                                                <div className="col-12 col-xs-3 col-md-3 col-lg-3">
                                                    <div>
                                                    <b style={{ color: '#000000' }}>Payment Date </b>
                                                    
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        style={{ marginTop: '4%'}}
                                                        name="paymentDate"
                                                        value={searchForm.paymentDate}
                                                    />
                                                    </div>
                                                </div>
                                                {/*<div className="col-12 col-xs-3 col-md-3 col-lg-3">*/}
                                                {/*    <div className="form-group">*/}
                                                {/*        <label>Status</label>*/}
                                                {/*        <select className="form-control" name="status" value={searchForm.status}>*/}
                                                {/*            <option value="">Select</option>*/}
                                                {/*            <option value="active">Active</option>*/}
                                                {/*            <option value="deactivate">Deactivate</option>*/}
                                                {/*        </select>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/*<div className="col-12 col-xs-3 col-md-3 col-lg-3">*/}
                                                {/*    <div className="form-group">*/}
                                                {/*        <label>Type</label>*/}
                                                {/*        <select className="form-control" name="type" value={searchForm.type}>*/}
                                                {/*            <option value="">All</option>*/}
                                                {/*            <option value="ROOT">Root</option>*/}
                                                {/*        </select>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </div>

                                        </div>
                                        <div className="col-12 col-xs-12 col-md-3 col-lg-3 mt-1 text-end">
                                            <div className="form-group mt20">

                                                <button type="submit"
                                                    className="btn btn-md btn-primary shadow-sm  mr-2"> Submit
                                                </button>
                                                <button type="button" onClick={downloadList}
                                                    className="btn btn-md btn-primary shadow-sm  mr-2"> Download List
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Prospect No.</th>
                                                <th>Auction ID</th>
                                                <th>Auction Date</th>
                                                <th>Borrowers Name</th>
                                                <th>Reserve Price</th>
                                                <th>Bidder Name</th>
                                                <th>Payment Amount</th>
                                                <th>Payment Date</th>
                                                <th>Payment Mode</th>
                                                <th>Payment Type</th>
                                                <th>Payment Status</th>
                                                <th>Bidder Tagging</th>
                                                {/* <th>Property Status</th> */}
                                                {permission?.moduleList?.update === false ? null :
                                                    <th>Action</th>}
                                            </tr>
                            
                                        </thead>

                                        <tbody>

                                            {
                                                lists.length > 0 ? lists.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td>{(index + 1) + ((pageNo - 1) * 20)}</td>
                                                        <td>{item.prospectNo}</td>
                                                        <td>{item?.propertyDetails?.auctionId}</td>
                                                        <td>{item?.propertyDetails?.auctionDate !== '' ? (item?.propertyDetails?.auctionDate) : "-"}</td>
                                                        <td>{item?.propertyDetails?.borrowerName}</td>
                                                        <td>{item?.propertyDetails?.price}</td>
                                                        <td>{item.bidder.length > 0 ? item.bidder[0].name : "-"}</td>
                                                        <td>{item.amount}</td>
                                                        <td>{item.paymentMode == 1 ? "-" : item.paymentDetails?.paymentReceiveDate?.split(" ")[0]}</td>
                                                        <td>{item.paymentDetails?.modeOfPayment}</td>
                                                        <td>{item.paymentStatus != "REJECTED" ? item.paymentFor === 1 && item?.bidderBids?.isFullEmdPaid ? "EOI" : item.paymentFor === 1 && !item?.bidderBids?.isFullEmdPaid ? "Partial_EOI" :
                                                            item.paymentFor === 2 && item?.bidderBids?.isFullEmdPaid ? "EMD" : item.paymentFor === 2 && !item?.bidderBids?.isFullEmdPaid ? "Partial_EMD" : item.paymentFor === 3 && item?.bidderBids?.isFullEmdPaid && !item?.bidderBids?.isFullBSPaid ? "Partial Balance Sale" : 'Balance Sale Amount': 'NA'}</td>
                                                        <td>{item.paymentStatus != "REJECTED" ? item?.paymentStatus.replace(/\b[a-z]/g, (x) => x.toUpperCase()) : "Failed"}
                                                            {item.paymentStatus === 'REFUNDED' ? " " + item.refundDate : ''}
                                                        </td>
                                                        {/* ? represent if and : represent else*/}
                                                        <td>{item.propertyDetails.auctionStatus === 'COMPLETED' ?
                                                            item?.bidderBids?.isBidAwarded == true || item.paymentFor === 3 ? "H1"
                                                                : item?.bidderBids?.isParticipatedInAuction == false ? "Not Partcipated" : "Lost"
                                                            : new Date(item?.propertyDetails?.auctionDate) < new Date() && item.paymentFor === 2 && item?.bidderBids?.isParticipatedInAuction == false ? 'Non Partcipated' : '-'}</td>
                                                        {/* <td>{item?.propertyDetails?.propertyStatus}</td> */}
                                                        {permission?.moduleList?.update === false ? null : <td>
                                                            {/* { item.paymentStatus == 'COMPLETED' ? item.bidderBids?.isBidAwarded == false ? <EmdRefundModal bidData={item} callback={getList} /> : "-" : item.paymentStatus == "REFUNDED" ? "-" : "-" } */}
                                                            {item.refundShown && !item.isEoi && (item.paymentDetails !== null || item.paymentDetails !== undefined) && (item.paymentDetails && item.paymentDetails.callerReferenceNo !== '') && item.propertyBiddersCount > 1 ? <EmdRefundModal bidData={item} callback={getList} /> : "-"}
                                                        </td>}
                                                    </tr>

                                                }) : <tr>
                                                    <td colSpan={12} style={{ textAlign: 'center' }}>No records</td>
                                                </tr>
                                            }

                                        </tbody>

                                    </table>


                                </div>
                                <div className="justify-content-center mt-3 d-flex">
                                    <PaginationNew perPage={perPage} totalItems={totalItems} currentPage={pageNo}
                                        handler={pageChangeHandler} />
                                </div>
                            </div>
                        </div>
                    </>}


            </div>
        </>
    )
}

export default EmdPayments